// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';
import React, { useMemo } from 'react';
import { Breadcrumb, type BreadcrumbProps, type BreadcrumbItemProps } from '/app/node_modules/antd';

import useAntdBreadcrumbProps from './useAntdBreadcrumbProps';

export interface IPageRouteBreadcrumbProps extends BreadcrumbProps {
  onPatchItems?: (items: Array<BreadcrumbItemProps>) => Array<BreadcrumbItemProps>;
}

const PageRouteBreadcrumb: React.FC<IPageRouteBreadcrumbProps> = ({ onPatchItems, ...pageRouteBreadcrumbProps }: IPageRouteBreadcrumbProps) => {
  const { items: routeItems, ...antdBreadcrumbProps } = useAntdBreadcrumbProps();

  const items = useMemo(() => {
    if (onPatchItems) {
      return onPatchItems(routeItems);
    }
    return routeItems;
  }, [routeItems, onPatchItems])

  return (
    <Breadcrumb
      {...antdBreadcrumbProps}
      items={items}
      {...pageRouteBreadcrumbProps}
    />
  )
}

PageRouteBreadcrumb.displayName = 'PageRouteBreadcrumb';

export default PageRouteBreadcrumb;
