import _ from 'lodash';
import { Player, BigPlayButton } from 'video-react';
import Image from './Image';

import 'video-react/dist/video-react.css';

export interface IVideoPlayerProps {
  /**
   * 封面路徑
   */
  cover?: string;
  /**
   * 影片路徑
   */
  src?: string;
}

export default function (props: IVideoPlayerProps) {

  const renderVideo = () => {
    return (
      <Player
        playsInline
        autoPlay={false}
        poster={props.cover}
        src={props.src}>
        <BigPlayButton position="center" />
      </Player>
    );
  }

  if (_.isUndefined(props.src)) {
    return <Image src={props.cover ?? null} />;
  }

  return (
    <div className="w-full h-full bg-gray-100 my-auto">
      {
        renderVideo()
      }
    </div>
  )
}
