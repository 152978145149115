import _ from 'lodash';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import APIClient from '../APIClient';
import { ICollection, IDataset, IDatasetFile } from './types';

export interface IGET_Collection_SearchParams {
  keyword?: string;
  page?: number;
  pageSize?: number;
}

export type IPOST_Dataset_Payload = Pick<IDataset, 'type' | 'site' | 'siteKey' | 'key' | 'data'>;

export interface IPOST_DatasetFile_Payload {
  sort?: number;
  key: string;
  sourceUrl?: string;
  file: File;
}

export interface IPaginationCollection {
  data: Array<ICollection>,
  pagination: {
    current: number,
    pageSize: number,
    total: number
  }
}

class DatasetController {
  private api: APIClient;

  constructor(api: APIClient) {
    this.api = api;
  }

  async GET_CollectionSite(config: AxiosRequestConfig = {}) {
    return this.api.client.get<Array<string>>(`/collection.site`, config);
  }

  async GET_Collection(site?: string, searchParams: IGET_Collection_SearchParams = {}, config: AxiosRequestConfig = {}) {
    return this.api.client.get<IPaginationCollection>(`/collection/${site ?? ''}`, {
      params: searchParams,
      ...config
    });
  }

  async GET_CollectionByKey(site: string, siteKey: string, config: AxiosRequestConfig = {}) {
    return this.api.client.get<ICollection>(`/collection/${site}/${siteKey}`, config);
  }

  async POST_Dataset(payload: IPOST_Dataset_Payload, config: AxiosRequestConfig = {}) {
    return this.api.client.post<IDataset>(`/dataset`, payload, config);
  }

  async POST_DatasetFile(datasetHashId: string, payload: IPOST_DatasetFile_Payload, config: AxiosRequestConfig = {}) {
    return this.api.client.postForm<IDatasetFile>(`/dataset/${datasetHashId}/file`, payload, {
      env: {
        FormData // Node.js: native FormData constructor
      }
    });
  }
}

export default DatasetController;
