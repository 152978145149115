// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import AppAuthContext, { type IAppAuthContext } from './AppAuthContext';

function useAppAuthGuardWithType<S extends object, A extends object, E extends object, G extends object>(): G {
  const { guard } = React.useContext(AppAuthContext) as unknown as IAppAuthContext<S, A, E, G>;
  return guard;
}

export default useAppAuthGuardWithType;
