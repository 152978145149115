import { IRouteProps } from '@/contexts/pageRouteProps';

import { Space, Typography } from 'antd';

import Logo from '@/components/Logo';

export const routeProps: IRouteProps = {
  pageLayout: 'MainLayout',
  appAuth: {
    policy: 'isLoggedIn'
  },
  breadcrumb: {
    title: '首頁'
  }
};

function HomePage() {
  return (
    <div className="w-screen h-[calc(100vh-48px)] text-center flex justify-center items-center">
      <Space direction="vertical" size="middle">
        <Logo size={64} style={{ backgroundColor: '#fff' }} />
        <Typography.Title level={5}>
          <span className="text-gray-400">
            這邊是首頁<br />但是還沒有想到要放什麼
          </span>
        </Typography.Title>
      </Space>
    </div>
  );
}

export default HomePage;
