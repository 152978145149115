import _ from 'lodash';

import { type IAppAuth } from '@@/plugin-appAuth/types.d';
import { AppAuth } from '@@/plugin-appAuth';
import { createAuth0Client, type Auth0Client, type User } from '@auth0/auth0-spa-js';

import config from '@/config';

// 匯出可用方法至專案中
export const { useAppAuthState, useAppAuthEffect, useAppAuthGuard, AppAuthGuard } = AppAuth.getExport<IAppAuthState, IAppAuthAction, IAppAuthEffect, IAppAuthGuard>();

// 帳號資料型態
export type IAppAuthAccount = {};

// #region AppAuth 所需介面

// Dispatch Action 類型
export enum AppAuthActionType {
  /**
   * 設定 Auth0 物件
   */
  SET_AUTH0 = 'SET_AUTH0',
  /**
   * 設定 Auth0 使用帳號
   */
  SET_USER = 'SET_USER',
  /**
   * 清除當前帳號
   */
  CLEAN_USER = 'CLEAN_USER'
}


export interface IAppAuthState {
  /**
   * Auth0 物件
   */
  auth0?: Auth0Client;
  /**
   * 當前 user 資料
   * (來自 Auth0)
   */
  user?: User
}

export type IAppAuthAction =
  { type: AppAuthActionType.SET_AUTH0, payload: Auth0Client } |
  { type: AppAuthActionType.SET_USER, payload: User | undefined }


export interface IAppAuthEffect {
  /**
   * 處理 Auth0 登入重新導向
   */
  handleRedirectCallback(): Promise<void>;
  /**
   * 登出
   */
  logout(): Promise<void>;
}


export interface IAppAuthGuard {
  isAnonymous(): boolean;
  isLoggedIn(): boolean;
}


// #endregion

const appAuth: IAppAuth<IAppAuthState, IAppAuthAction, IAppAuthEffect, IAppAuthGuard> = {
  state: async () => {
    // 由於 Auth0-react 無法取得或設定 client, 因此需要使用 auth0-spa-js 自行處理登入機制
    const auth0Client = await createAuth0Client(config.Auth0);
    // 嘗試取得使用者
    const user = await auth0Client.getUser();
    return {
      auth0: auth0Client,
      user,
    };
  },
  reducer(state: IAppAuthState, action: IAppAuthAction) {
    let nextState = state;
    switch (action.type) {
      case AppAuthActionType.SET_AUTH0:
        nextState = {
          ...state,
          auth0: action.payload,
        };
        break;
      case AppAuthActionType.SET_USER:
        nextState = {
          ...state,
          user: action.payload
        };
        break;
    }
    return nextState;
  },
  effect({ state, dispatch }) {
    return {
      async handleRedirectCallback() {
        const { auth0 } = state;
        if (auth0) {
          await auth0.handleRedirectCallback();
          const user = await auth0.getUser();
          dispatch({
            type: AppAuthActionType.SET_USER,
            payload: user
          });
        }
      },
      async logout() {
        const { auth0 } = state;
        if (auth0) {
          await auth0.logout({
            logoutParams: {
              returnTo: window.location.origin
            }
          });
        }
      }
    }
  },
  guard({ state }) {
    return {
      isAnonymous: () => {
        const { auth0, user } = state;
        return _.isUndefined(auth0) || _.isUndefined(user);
      },
      isLoggedIn: () => {
        const { auth0, user } = state;
        return !_.isUndefined(auth0) && !_.isUndefined(user);
      }
    }
  },
  subscriptions: {
  }
}

export default appAuth;


