import React from 'react';
import { Avatar } from 'antd';

import LogoImg from './OwO.png';

interface ILogoProps {
  // Logo 大小 (預設 = 32)
  size?: number;
  style?: React.CSSProperties
}

const Logo: React.FC<ILogoProps> = ({ size = 32, style = {} }) => {
  return (
    <Avatar
      size={size}
      shape='circle'
      src={LogoImg}
      style={{
        backgroundColor: '#eee',
        ...style
      }}
    />
  );
}

Logo.displayName = 'Logo';

export default Logo;