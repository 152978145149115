// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';
import { type IRoute } from 'umi';

import { getRoutes } from '@@/core/route';

async function getRouteByKey(key: string): Promise<IRoute | undefined> {
  const { routes } = await getRoutes();
  return _.get(routes, key);
}

export default getRouteByKey;
