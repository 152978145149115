import { Navigate, useLocation, useSearchParams } from 'umi';
import { PageRouteProps } from '@@/plugin-pageRouteProps';
import { type IRouteProps as IRoutePropsWithType, type IPageRouteProps } from '@@/plugin-pageRouteProps/types.d';
import { type IAppAuthState, type IAppAuthAction, type IAppAuthEffect, type IAppAuthGuard } from '@/contexts/appAuth';

export type IRouteProps = IRoutePropsWithType<IAppAuthState, IAppAuthGuard>;

export const { useAntdMenuProps, usePageRouteContainer } = PageRouteProps.getExport<IAppAuthState, IAppAuthAction, IAppAuthEffect, IAppAuthGuard>();

const DefaultFallbackComponent = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  searchParams.append('returnTo', location.pathname)
  return <Navigate to={`/login?${searchParams.toString()}`} replace={true} />
}

const pageRouteProps: IPageRouteProps = {
  appAuth: {
    defaultFallback: DefaultFallbackComponent
  }
}

export default pageRouteProps;
