// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';
import { IRoute } from 'umi';

import { getRoutes } from '@@/core/route';
import routeProps from '@@/core/routeProps';

import type { IRouteProps } from '../types.d';
import folderProps, { FOLDER_ROUTEPROPS_PREFIX } from './folderProps';

//#region 轉換後資料型態
export type IRouteMap<AppAuthState extends object, AppAuthGuard extends object> = Record<string, IRouteMapItem<AppAuthState, AppAuthGuard>>;

export type IRouteMapItem<AppAuthState extends object, AppAuthGuard extends object> = IRouteProps<AppAuthState, AppAuthGuard> & {
  key: React.Key
  route?: IRoute
  children?: IRouteMap<AppAuthState, AppAuthGuard>
}
//#endregion

/**
 * 將 路由表, routeProps, folderProps 根據其階層合併為 IRouteMap
 */
async function getPageRouteMap<AppAuthState extends object, AppAuthGuard extends object>(): Promise<IRouteMap<AppAuthState, AppAuthGuard>> {
  // 取得頁面路由表
  const { routes } = await getRoutes();
  // 根據 routeProps 資料建立基礎資料, 並合併路由內容
  const res = _.reduce(routeProps, (res, value, key) => {
    // 以 / 分割確定目錄深度
    const layer = key.split('/');
    if (layer.length === 1) {
      return {
        ...res,
        [key]: {
          ...value,
          key,
          route: _.get(routes, key)
        }
      };
    }
    // 超過 1 代表位於目錄下
    const childrenLayer = key.split('/');
    childrenLayer.pop();
    // 取得該層的 children 內容
    const childrenPath = _.flatMap(_.map(childrenLayer, l => `${FOLDER_ROUTEPROPS_PREFIX}${l}`), (v) => [v, 'children']);
    const children = _.get(res, childrenPath, {});
    _.set(res, childrenPath, {
      ...children,
      [key]: {
        ...value,
        key,
        route: _.get(routes, key)
      }
    });
    return res;
  }, {});
  // 根據 folderProps 建立資料夾資料
  _.each(folderProps, (value, key) => {
    // 以 / 分割確定目錄深度
    const layer = key.split('/');
    if (layer.length === 1) {
      const currentMenu = _.get(res, `${FOLDER_ROUTEPROPS_PREFIX}${key}`, {});
      _.set(res, `${FOLDER_ROUTEPROPS_PREFIX}${key}`, {
        ...value,
        ...currentMenu,
        key: `${FOLDER_ROUTEPROPS_PREFIX}${key}`,
      });
    } else {
      // 超過 1 代表位於目錄下
      // 取得該層的 children 內容
      const childrenLayer = key.split('/');
      const childrenPath = _.flatMap(_.map(childrenLayer, l => `${FOLDER_ROUTEPROPS_PREFIX}${l}`), (v) => [v, 'children']);
      // 由於需要訪問的是 submenu 節點, 因此需要移除最後一個 children
      childrenPath.pop();
      const currentSubMenu = _.get(res, childrenPath, {});
      const inferKey = _.last(childrenPath);
      _.set(res, childrenPath, {
        ...value,
        ...currentSubMenu,
        // 如果節點已經為 FOLDER_ROUTEPROPS_PREFIX 開頭，則不再次附加
        key: _.startsWith(inferKey, FOLDER_ROUTEPROPS_PREFIX) ? inferKey : `${FOLDER_ROUTEPROPS_PREFIX}${inferKey}`,
      })
    }
  });
  return res as IRouteMap<AppAuthState, AppAuthGuard>;
}

export default getPageRouteMap;
