// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';

import { type IAppAuthPolicy } from './types.d';

/**
 * 傳入狀態資料及政策判斷政策是否放行
 * @param state S 狀態資料
 * @param guard G 政策資料
 * @param policy IAppAuthPolicy<S> | Array<IAppAuthPolicy<S>> 政策或政策組合
 * @return boolean 是否放行
 */
const invokeAppAuthPolicyWithType = <S extends object, G extends object>(state: S, guard: G, policy: IAppAuthPolicy<S> | Array<IAppAuthPolicy<S>>): boolean => {
  if (_.isArray(policy)) {
    // 如果為多個policy資料時, 只要有一個未通過就不放行
    return _.reduce(policy, (res, p) => {
      // 當有未通過的權限, 則不繼續驗證後續的權限設定
      if (res === false) return res;
      const pIsPass = invokeAppAuthPolicyWithType<S, G>(state, guard, p);
      return res && pIsPass;
    }, true);
  }
  if (_.isString(policy)) {
    // 當為字串時
    const [guardName, guardParamsStr] = policy.split(':');
    if (_.hasIn(guard, guardName)) {
      const wrapperParams = _.isString(guardParamsStr) ? guardParamsStr.split(',') : [];
      const res = _.invoke(guard, guardName, wrapperParams) as unknown;
      if (_.isBoolean(res)) {
        return res;
      }
      // 當回傳值不為布林值時, 回應 false
      console.warn(`[plugin-appAuth] ${guardName} should return boolean, please check appAuth context config.`);
      return false;
    }
    // 當沒有對應名稱時, 回應 false
    console.warn(`[plugin-appAuth] ${guardName} is not contains in guard list, please check appAuth context config.`);
    return false;
  }
  if (_.isFunction(policy)) {
    const res = policy({ state });
    if (_.isBoolean(res)) {
      return res;
    }
    // 當回傳值不為布林值時, 回應 false
    console.warn(`[plugin-appAuth] policy function should return boolean, please check function definition.`);
    return false;
  }
  if (_.isPlainObject(policy)) {
    const { name, params } = policy;
    if (_.hasIn(guard, name)) {
      const res = _.invoke(guard, name, ...params) as unknown;
      if (_.isBoolean(res)) {
        return res;
      }
      // 當回傳值不為布林值時, 回應 false
      console.warn(`[plugin-appAuth] ${name} should return boolean, please check appAuth context config.`);
      return false;
    }
    // 當沒有對應名稱時, 回應 false
    console.warn(`[plugin-appAuth] ${name} is not contains in guard list, please check appAuth context config.`);
    return false;
  }
  console.warn(`[plugin-appAuth] ${JSON.stringify(policy)} is not valid, please check appAuth context config.`);
  return false;
}

export default invokeAppAuthPolicyWithType;
