// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React, { type Dispatch } from 'react';

export interface IAppAuthContext<S extends object, A extends object, E extends object, G extends object> {
  state: S;
  dispatch: Dispatch<A>;
  effect: E;
  guard: G;
}

const defaultAppAuthContextValue: IAppAuthContext<object, object, object, object> = {
  state: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  effect: {},
  guard: {},
}

const AppAuthContext = React.createContext<IAppAuthContext<object, object, object, object>>(defaultAppAuthContextValue);
AppAuthContext.displayName = 'AppAuthContext';

export default AppAuthContext;
