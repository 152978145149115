// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';
import { type MenuProps } from '/app/node_modules/antd';
import { useAsyncEffect } from '/app/node_modules/ahooks';

import { useNavigate } from 'umi';
import { useState } from 'react';

import useAppAuthStateWithType from '@@/plugin-appAuth/context/useAppAuthStateWithType';
import useAppAuthGuardWithType from '@@/plugin-appAuth/context/useAppAuthGuardWithType';
import invokeAppAuthPolicyWithType from '@@/plugin-appAuth/invokeAppAuthPolicyWithType';

import type { IMenuRouteProps } from './types.d';
import { FOLDER_ROUTEPROPS_PREFIX } from '../pageRoute/folderProps';
import getRouteByKey from '../pageRoute/getRouteByKey';
import getPageRouteList, { type IRouteList, type IRouteListItem } from '../pageRoute/getPageRouteList';

// 內部使用型別
type GetElementType<T extends any[]> = T extends (infer U)[] ? U : never;

export type IMenuItemProps = IMenuRouteProps & GetElementType<NonNullable<MenuProps['items']>>;

/**
 * 回傳根據路由資訊組合完成部分 Antd Menu 屬性
 * @returns 
 */
function useAntdMenuProps<AppAuthState extends object, AppAuthAction extends object, AppAuthEffect extends object, AppAuthGuard extends object>() {
  // 路由控制
  const navigate = useNavigate();

  // 選單資料
  const [menuItems, setMenuItems] = useState<Array<IMenuItemProps>>([]);

  // 取得權限資訊
  const state = useAppAuthStateWithType<AppAuthState, AppAuthAction, AppAuthEffect, AppAuthGuard>();
  const guard = useAppAuthGuardWithType<AppAuthState, AppAuthAction, AppAuthEffect, AppAuthGuard>();

  // 計算選單資料
  useAsyncEffect(async () => {
    const routeList = await getPageRouteList<AppAuthState, AppAuthGuard>();
    const flatRouteMapToMenu = (routeItem: IRouteList<AppAuthState, AppAuthGuard>) => {
      const res = _.reduce(routeItem, (r, i: IRouteListItem<AppAuthState, AppAuthGuard>) => {
        // 取得 & 計算子項目
        const childrenMenuItem = flatRouteMapToMenu(_.get(i, 'children', [])) as Array<IMenuItemProps>;
        // 當資料中包含 menu 部分才加入
        if (_.hasIn(i, 'menu')) {
          // 當子項目沒有內容且沒有對應路由資料時, 判定為資料夾不顯示於選單中
          if (childrenMenuItem.length === 0 && !_.hasIn(i, 'route')) {
            return r;
          }
          // 處理自身項目
          // 權限檢查
          if (_.hasIn(i, 'appAuth')) {
            const appAuthPass = invokeAppAuthPolicyWithType<AppAuthState, AppAuthGuard>(state, guard, i.appAuth?.policy ?? []);
            // 如果未通過權限驗證則不顯示於選單中
            if (!appAuthPass) {
              return r;
            }
          }
          // 選單資料回傳
          const menuItem: IMenuItemProps = {
            key: i.key,
            index: 0,
            ...i.menu
          }
          // 當資料中包含 children 部分需要加入至回傳中
          if (childrenMenuItem.length > 0) {
            r.push({
              ...menuItem,
              children: childrenMenuItem
            } as IMenuItemProps)
          } else {
            r.push(menuItem);
          }
          return r;
        }
        // 如果不包含 menu 資訊且具有 children 資料時, 則建立一個簡易子選單避免 children 內容被排除
        if (!_.hasIn(i, 'menu') && childrenMenuItem.length > 0) {
          // 找出不包含 FOLDER_ROUTEPROPS_PREFIX 前綴的路由用於計算
          const inferKeyChildren = _.filter(childrenMenuItem, c => !_.startsWith(_.get(c, 'key', ''), FOLDER_ROUTEPROPS_PREFIX))
          const inferKeyLayer = _.get(inferKeyChildren, '0.key', _.uniqueId('unknown-')).split('/');
          if (inferKeyLayer.length >= 2) {
            // 移除頁面層
            inferKeyLayer.pop();
          }
          const inferKey = `${FOLDER_ROUTEPROPS_PREFIX}${_.last(inferKeyLayer)}`;
          r.push({
            key: inferKey,
            index: 0,
            label: inferKey,
            children: childrenMenuItem
          });
          return r;
        }
        // 其餘情況如果不包含 menu 資訊, 則不加入至其中
        return r;
      }, [] as Array<IMenuItemProps>);
      // 進行排序
      return _.orderBy(res, ['index', 'label'], ['asc', 'asc']);
    }
    setMenuItems(flatRouteMapToMenu(routeList));
  }, [state, guard]);

  // 預設頁面點擊事件
  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    void getRouteByKey(key).then((route) => {
      if (route) {
        navigate(route.path, { replace: true })
      }
    });
  }  

  // 回傳選單資料
  return {
    items: menuItems,
    onClick: handleMenuClick,
  } as Partial<MenuProps>;
}

export default useAntdMenuProps;
