// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import getExport from './getExport';

interface IPluginAppAuthExport {
  getExport: typeof getExport;
}

/**
 * AppAuth 對外方法
 * @returns 
 */
export const AppAuth: IPluginAppAuthExport = {
  getExport
};
