import _ from "lodash";
import React, { useState } from "react";
import { PictureOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


export interface IImageProps {
  /**
   * 圖檔路徑
   */
  src: string | null
  /**
   * 是否自動貼齊中間
   */
  alignCenter?: boolean
}

export default function (props: IImageProps) {
  const { src, alignCenter } = props;
  const [loading, setLoading] = useState(true);

  const renderNoImage = () => (
    <div className="w-full h-full grid items-center justify-items-center">
      <div className="text-gray-300 text-center">
        <div className="text-4xl">
          <PictureOutlined />
        </div>
        <div className="font-bold">No Image</div>
      </div>
    </div>
  );

  const renderLoading = () => (
    <div
      style={loading ? {} : { display: 'none' }}
      className="w-full h-full grid items-center justify-items-center"
    >
      <div className="text-gray-300 text-center">
        <div className="text-4xl">
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      </div>
    </div>
  )

  const renderImage = () => {
    if (_.isEmpty(src) || _.isNull(src)) {
      return renderNoImage();
    }
    return (
      <React.Fragment>
        {renderLoading()}
        {
          alignCenter ? (
            <img
              style={loading ? { display: 'none' } : {}}
              className="max-w-full max-h-full w-auto h-auto absolute top-0 bottom-0 left-0 right-0 m-auto"
              src={src}
              onLoad={() => setLoading(false)}
            />
          ) : (
            <img
              style={loading ? { display: 'none' } : {}}
              className="max-w-full max-h-full w-auto h-auto m-auto"
              src={src}
              onLoad={() => setLoading(false)}
            />
          )
        }
      </React.Fragment>
    )
  }

  return (
    <div className="w-full h-full bg-gray-100 my-auto">
      {
        renderImage()
      }
    </div>
  )
}
