// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

import PageRouteMenu from './menu/PageRouteMenu';

import PageRouteBreadcrumb from './breadcrumb/PageRouteBreadcrumb';

/**
 * 取得 plugin-pageRouteProps 中可供頁面使用的容器組件
 */
function usePageRouteContainer() {
  return {
    PageRouteMenu,
    PageRouteBreadcrumb,
  }
}

export default usePageRouteContainer;
