// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

import { ApplyPluginsType, type PluginManager } from 'umi';
import { getPluginManager } from '@@/core/plugin';

import AppAuthContextProvider from './context/AppAuthContextProvider';

import type { IAppAuth } from './types.d';

export function rootContainer(container: JSX.Element) {
  // 建立初始化後的 Auth 服務提供者物件
  const appAuthConfig = (getPluginManager() as PluginManager).applyPlugins({
    key: 'appAuth',
    type: ApplyPluginsType.modify,
  }) as IAppAuth<object, object, object, object>;
  return (
    <AppAuthContextProvider appAuthConfig={appAuthConfig}>
      {container}
    </AppAuthContextProvider>
  )
}
