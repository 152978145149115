// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import getExport from './getExport';

interface IPluginPageRoutePropsExport {
  getExport: typeof getExport;
}

/**
 * PageRouteProps 對外方法
 * @returns 
 */
export const PageRouteProps: IPluginPageRoutePropsExport = {
  getExport
};
