import { AxiosRequestConfig, AxiosResponse } from 'axios';
import APIClient from '../APIClient';
import { IDataset, IDatasetFile } from './types';

export interface IPOST_CrawlerDataset_Payload {
  site: string;
  keyword: string;
}

class CrawlerController {
  private api: APIClient;

  constructor(api: APIClient) {
    this.api = api;
  }

  async POST_CrawlerDataset(payload: IPOST_CrawlerDataset_Payload, config: AxiosRequestConfig = {}) {
    return this.api.client.post<void>(`/crawler/dataset`, payload, config);
  }
}

export default CrawlerController;
