import _ from 'lodash';
import { type Auth0ClientOptions } from '@auth0/auth0-spa-js';

/**
 * Window 複寫
 */
declare global {
  interface Window {
    env: Record<string, any>
  }
}

/**
 * Config 資料型態
 */
export interface IConfig {
  Auth0: Auth0ClientOptions;
  Service: {
    OwOb: {
      BaseURL: string;
      S3URL: string;
    }
  }
}

/**
 * 如果全域沒有指派物件時, 先指派一個
 */
if (_.isUndefined(window.env)) {
  window.env = {};
}

const config: IConfig = {
  Auth0: {
    domain: window.env.UMI_APP_AUTH0_DOMAIN ?? process.env.UMI_APP_AUTH0_DOMAIN ?? '',
    clientId: window.env.UMI_APP_AUTH0_CLIENTID ?? process.env.UMI_APP_AUTH0_CLIENTID ?? '',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/login`,
      audience: window.env.UMI_APP_AUTH0_AUDIENCE ?? process.env.UMI_APP_AUTH0_AUDIENCE ?? '',
    },
    useRefreshTokens: true,
    cacheLocation: 'localstorage'
  },
  Service: {
    OwOb: {
      BaseURL: window.env.UMI_APP_OWOB_BASEURL ?? process.env.UMI_APP_OWOB_BASEURL ?? '',
      S3URL: window.env.UMI_APP_OWOB_S3URL ?? process.env.UMI_APP_OWOB_S3URL ?? '',
    }
  }
}

export default config;