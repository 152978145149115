// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';
import { type BreadcrumbProps } from '/app/node_modules/antd';
import { useAsyncEffect } from '/app/node_modules/ahooks';

import { Link, useParams, useLocation, useSelectedRoutes } from 'umi';
import { useState } from 'react';

import folderProps, { FOLDER_ROUTEPROPS_PREFIX } from '../pageRoute/folderProps';
import { getRoutes } from '@@/core/route';

function useAntdBreadcrumbProps() {
  // 當前路由資料
  const params = useParams();
  const location = useLocation();
  const selectedRoutes = useSelectedRoutes();

  // 選單資料
  const [items, setItems] = useState<BreadcrumbProps['items']>([]);

  // 計算選單資料
  useAsyncEffect(async () => {
    const getBreadcrumbItems = async () => {
      // 取得路由資料
      const { routes } = await getRoutes();
      const lastRoute = _.get(_.last(selectedRoutes), 'route');
      const lastRouteId = _.get(lastRoute, 'id') as string | undefined;
      if (_.isUndefined(lastRoute) || _.isUndefined(lastRouteId)) {
        return [];
      }
      // 以 / 分割確定目錄深度
      const layer = lastRouteId.split('/');
      // 取得當前頁面麵包屑
      const lastBreadcrumb = {
        key: lastRouteId,
        title: _.last(layer),
        ..._.get(lastRoute, 'breadcrumb', {})
      };
      if (layer.length === 1) {
        // 只有一層不需要展開資料夾
        return [lastBreadcrumb];
      }
      // 超過一層的情形, 需要取得沿途的資料夾資訊
      layer.pop();
      const folderItem = _.reduce(layer, (res, l, index) => {
        const folderPath = layer.slice(0, index + 1);
        const folderBreadcrumb = {
          key: `${FOLDER_ROUTEPROPS_PREFIX}${l}`,
          title: `${FOLDER_ROUTEPROPS_PREFIX}${l}`,
          ..._.get(folderProps, [folderPath.join('/'), 'breadcrumb'], {})
        };
        // 當資料夾有對應的路由資料時, 以 Link 組件包裹
        const routeId = _.findKey(routes, r => r.id === folderPath.join('/'));
        const routePath = _.get(routes, [routeId, 'path']);
        // 如果存在於路由表中且不為當前路由
        if (!_.isUndefined(routeId) && !(new RegExp(`^(\/?)${routePath}$`).test(location.pathname))) {
          if (routePath.includes(':') || routePath.includes('*')) {
            // 如果路由帶有參數, 需要將參數帶入
            let routePathWithParams = routePath;
            _.each(params, (v, k) => {
              routePathWithParams = routePathWithParams.replace((k === '*' ? k : `:${k}`), v);
            });
            res.push({
              folderBreadcrumb,
              title: (
                <Link to={routePathWithParams}>{_.get(folderBreadcrumb, 'title', `${FOLDER_ROUTEPROPS_PREFIX}${l}`)}</Link>
              )
            });
          } else {
            // 路由不含參數時, 直接使用路由表中的路由
            res.push({
              folderBreadcrumb,
              title: (
                <Link to={routePath}>{_.get(folderBreadcrumb, 'title', `${FOLDER_ROUTEPROPS_PREFIX}${l}`)}</Link>
              )
            });
          }
        } else {
          res.push(folderBreadcrumb);
        }
        return res;
      }, [] as BreadcrumbProps['items']);
      return [
        ...folderItem,
        lastBreadcrumb
      ];
    }
    const items = await getBreadcrumbItems();
    setItems(items);
  }, [location]);

  return {
    items
  } as Partial<BreadcrumbProps>;
}

export default useAntdBreadcrumbProps;
