// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSelectedRoutes } from 'umi';
import { Menu, type MenuProps } from '/app/node_modules/antd';

import useAntdMenuProps, { type IMenuItemProps } from './useAntdMenuProps';
import { FOLDER_ROUTEPROPS_PREFIX } from '../pageRoute/folderProps';

export type IPageRouteMenuProps = MenuProps & {
  // 是否自動關閉與路由符合子選單
  autoCloseSubMenu?: boolean;
  // 路由與選單資料的比對方式
  selectedMatch?: 'full' | 'prefix';
  // 動態修改選單內容
  onPatchItems?: (items: Array<IMenuItemProps>) => Array<IMenuItemProps>;
};

const PageRouteMenu: React.FC<IPageRouteMenuProps> = ({ autoCloseSubMenu = false, selectedMatch = 'prefix', onPatchItems, ...pageRouteMenuProps }: IPageRouteMenuProps) => {
  // 當前路由資料
  const selectedRoutes = useSelectedRoutes();
  const location = useLocation();

  const { items: menuItems, ...antdMenuProps } = useAntdMenuProps();

  // 選單項目
  const items = useMemo(() => {
    if (onPatchItems) {
      return onPatchItems(menuItems);
    }
    return menuItems;
  }, [menuItems, onPatchItems]);

  // 被展開的目錄
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  // 當路由變更時，需要更新被展開的目錄
  useEffect(() => {
    // 計算應該被展開的目錄
    const getDefaultOpenKeys = () => {
      const lastRouteId = _.get(_.last(selectedRoutes), 'route.id') as string | undefined;
      if (_.isUndefined(lastRouteId)) {
        return [];
      }
      // 以 / 分割確定目錄深度
      const layer = lastRouteId.split('/');
      if (layer.length === 1) {
        // 只有一層不需要展開資料夾
        return [];
      }
      // 超過一層的情形, 需要將沿途的資料夾展開
      // ex. folderA/folderAA/page => [${FOLDER_ROUTEPROPS_PREFIX}folderA, ${FOLDER_ROUTEPROPS_PREFIX}folderAA]
      // 移除最後一階, 取得目錄路徑
      layer.pop();
      return _.map(layer, l => `${FOLDER_ROUTEPROPS_PREFIX}${l}`);
    }
    if (autoCloseSubMenu) {
      // 只設定現有展開的項目, 其他項目關閉
      setOpenKeys(getDefaultOpenKeys());
    } else {
      // 與現有展開的項目合併
      setOpenKeys(_.uniq([
        ...getDefaultOpenKeys(),
        ...openKeys
      ]));
    }
  }, [location, autoCloseSubMenu]);

  // 計算應該被選到的項目
  const selectedKeys = useMemo(() => {
    // 取得路由 id
    const lastRouteId = _.get(_.last(selectedRoutes), 'route.id') as string | undefined;
    if (_.isUndefined(lastRouteId)) {
      return [];
    }
    // 根據算法回傳不同內容
    // full: 完全一致的路由才會被選倒
    if (selectedMatch.toLowerCase() === 'full') {
      return [lastRouteId];
    }
    // prefix: 以路由前綴比對,符合的都算
    if (selectedMatch.toLowerCase() === 'prefix') {
      // 以 / 分割確定目錄深度
      const layer = lastRouteId.split('/');
      // 加入沿途的路由id
      return _.map(_.range(layer.length), i => {
        return _.take(layer, i + 1).join('/');
      });
    }
    return [];
  }, [location, selectedMatch]);

  // 目錄開關事件
  const handleMenuOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    setOpenKeys(openKeys);
  }

  return (
    <Menu
      {...antdMenuProps}
      items={items}
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      onOpenChange={handleMenuOpenChange}
      {...pageRouteMenuProps}
    />
  );
}

PageRouteMenu.displayName = 'PageRouteMenu';

export default PageRouteMenu;
