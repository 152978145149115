// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import useAppAuthStateWithType from './context/useAppAuthStateWithType';
import useAppAuthEffectWithType from './context/useAppAuthEffectWithType';
import useAppAuthGuardWithType from './context/useAppAuthGuardWithType';

import invokeAppAuthPolicyWithType from './invokeAppAuthPolicyWithType';

import AppAuthGuardWithType from './AppAuthGuard';

/**
 * 匯出對外方法
 * @returns 
 */
export default function getExport<S extends object, A extends object, E extends object, G extends object>() {
  const useAppAuthEffect = useAppAuthEffectWithType<S, A, E, G>;
  const useAppAuthState = useAppAuthStateWithType<S, A, E, G>;
  const useAppAuthGuard = useAppAuthGuardWithType<S, A, E, G>;

  const invokeAppAuthPolicy = invokeAppAuthPolicyWithType<S, G>;

  const AppAuthGuard = AppAuthGuardWithType<S, A, E, G>;

  return {
    useAppAuthState,
    useAppAuthEffect,
    useAppAuthGuard,
    invokeAppAuthPolicy,
    AppAuthGuard
  };
}
