import _ from 'lodash';
import React, { } from 'react';
import { Skeleton, SkeletonProps } from 'antd';

import styles from './SkeletonCard.less';

export type ISkeletonCardProps = Pick<SkeletonProps, 'active'>;

/**
 * 獨立的載入顯示卡片, 用於顯示載入過程
 */
const SkeletonCard: React.FC<ISkeletonCardProps> = (props: ISkeletonCardProps) => {
  return (
    <div
      className="bg-white border border-solid border-gray-300 hover:border-blue-300 rounded"
    >
      <div className="m-2">
        <div className="w-full relative aspect-[7/10]">
          <Skeleton.Image {...props} rootClassName={`${styles['skeleton-w-full']} w-full h-full grid items-center justify-items-center`} style={{ width: '100%', height: '100%' }} />
        </div>
        <div className="pt-2 text-sm">
          <div className="text-center text-blue-500 flex justify-center">
            <div style={{ width: '65%' }}>
              <Skeleton.Input {...props} size='small' rootClassName={styles['skeleton-w-full']} style={{ height: 18, minWidth: '100%', width: '100%' }} />
            </div>
          </div>
          <div className="pt-1 text-xs truncate">
            <Skeleton.Input {...props} size='small' style={{ height: 18 }} />
          </div>
        </div>
      </div>
    </div>
  )
}

SkeletonCard.displayName = 'SkeletonCard';

export default SkeletonCard;
