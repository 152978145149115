import axios, { type AxiosInstance, type CreateAxiosDefaults } from 'axios';

/**
 * Abstract class for API clients.
 */
abstract class APIClient {

  private _options: CreateAxiosDefaults;
  private _client: AxiosInstance;

  constructor(options: CreateAxiosDefaults) {
    this._options = options;
    this._client = axios.create(this._options);
  }

  /**
   * 取得預設設定
   * @returns 
   */
  getDefaultOptions(): CreateAxiosDefaults {
    return this._options;
  }

  /**
   * 修改預設設定
   * @param options 
   */
  setDefaultOptions(options: CreateAxiosDefaults) {
    this._options = {
      ...this._options,
      ...options,
    };
    this._client = axios.create(this._options);
  }

  /**
   * 取得 axios client
   */
  get client(): AxiosInstance {
    return this._client;
  }
}

export default APIClient;