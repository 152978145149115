// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';
import { IRoute } from 'umi';

import type { IRouteProps } from '../types.d';
import getPageRouteMap, { type IRouteMap } from './getPageRouteMap';

//#region 轉換後資料型態
export type IRouteList<AppAuthState extends object, AppAuthGuard extends object> = Array<IRouteListItem<AppAuthState, AppAuthGuard>>;

export type IRouteListItem<AppAuthState extends object, AppAuthGuard extends object> = IRouteProps<AppAuthState, AppAuthGuard> & {
  key: React.Key
  route?: IRoute
  children?: IRouteList<AppAuthState, AppAuthGuard>
}
//#endregion

/**
 * 將 路由表, routeProps, folderProps 根據其階層合併為 IRouteList
 */
async function getPageRouteList<AppAuthState extends object, AppAuthGuard extends object>(): Promise<IRouteList<AppAuthState, AppAuthGuard>> {
  // 取得選單 Map 結構
  const routeMap = await getPageRouteMap<AppAuthState, AppAuthGuard>();
  // 將選單資料攤平為陣列
  const flatRouteMap = (d: IRouteMap<AppAuthState, AppAuthGuard>) => {
    return _.map(Object.values(d), (v) => {
      const childrenList = flatRouteMap(v.children ?? {}) as IRouteList<AppAuthState, AppAuthGuard>;
      if (childrenList.length === 0) return _.omit(v, ['children']);
      return {
        ...v,
        children: childrenList
      }
    });
  }
  return flatRouteMap(routeMap) as IRouteList<AppAuthState, AppAuthGuard>;
}

export default getPageRouteList;
