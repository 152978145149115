import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { IAVCollectionData } from './types';
import { ICollectionCardProps } from '.';
import Image from './components/Image';
import { Descriptions, DescriptionsProps, Divider, Drawer, Space, Tabs, Tag } from 'antd';
import StickyBox from "react-sticky-box";
import VideoPlayer from './components/VideoPlayer';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export interface IAVCardProps extends ICollectionCardProps {
  /**
   * 資料內容
   */
  data: IAVCollectionData;
}

enum AVCardTab {
  INFO = 'INFO',
  ATTACHMENT = 'FANART',
  RAW = 'RAW'
}

const AVCard: React.FC<IAVCardProps> = ({ data, getFileUrl }) => {
  const [detailVisible, setDetailVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<AVCardTab>(AVCardTab.INFO);

  const titleUrl = useMemo<string | undefined>(() => {
    // 嘗試使用 poster
    const poster = _.first(data.poster);
    if (poster && _.isPlainObject(poster)) {
      return getFileUrl(poster);
    }
    // 如果沒有 poster, 嘗試用 cover
    const cover = _.first(data.cover);
    if (cover && _.isPlainObject(cover)) {
      return getFileUrl(cover);
    }
    return undefined;
  }, [data, getFileUrl]);

  const videoCoverUrl = useMemo<string | undefined>(() => {
    // 嘗試用 cover
    const cover = _.first(data.cover);
    if (cover && _.isPlainObject(cover)) {
      return getFileUrl(cover);
    }
    // 如果沒有 cover, 嘗試使用 poster
    const poster = _.first(data.poster);
    if (poster && _.isPlainObject(poster)) {
      return getFileUrl(poster);
    }
    return undefined;
  }, [data, getFileUrl]);

  const trailerUrl = useMemo<string | undefined>(() => {
    const trailer = _.first(data.trailer);
    if (trailer && _.isPlainObject(trailer)) {
      return getFileUrl(trailer)
    }
    return undefined;
  }, [data, getFileUrl]);

  const descriptionItems = useMemo<DescriptionsProps['items']>(() => {
    const res: DescriptionsProps['items'] = [
      {
        key: 'id',
        label: '資料編號',
        children: data.id
      },
      {
        key: 'dn',
        label: '番號',
        children: data.dn
      },
      {
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'key',
        label: '索引',
        children: data.key
      },
      {
        key: 'site',
        label: '來源網站',
        children: data.site
      },
      {
        key: 'siteKey',
        label: '來源網站索引',
        children: data.siteKey
      },
      {
        key: 'publisher',
        label: '發行者',
        children: data.maker ?? data.studio ?? '-'
      },
      {
        key: 'release',
        label: '發行日',
        children: data.release ?? '-'
      },
    ];
    if (data.runtime) {
      res.push({
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'runtime',
        label: '片長',
        children: data.runtime
      });
    }
    const actors = _.get(data, 'actors', []);
    if (actors.length > 0) {
      res.push({
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'actors',
        label: '演員',
        children: (
          <>
            {_.map(actors, (a, i) => (<Tag key={`avcard-${_.get(data, 'id', '')}-actors-${i}`}>{a}</Tag>))}
          </>
        )
      })
    }
    const tags = _.get(data, 'tags', []);
    if (tags.length > 0) {
      res.push({
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'tags',
        label: '標籤',
        children: (
          <Space wrap>
            {_.map(tags, (a, i) => (<Tag key={`avcard-${_.get(data, 'id', '')}-tags-${i}`}>{a}</Tag>))}
          </Space>
        )
      })
    }
    const outline = _.get(data, 'outline');
    if (!_.isEmpty(outline)) {
      res.push({
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'outline',
        label: '描述',
        children: outline
      })
    }
    return res;
  }, [data]);

  const renderAttachmentTab = () => {
    const resolveFileUrlListByKey = (d: IAVCollectionData, dk: string): Array<string> => {
      const dataValue = _.get(d, dk, []);
      if (_.isArray(dataValue)) {
        return _.map(dataValue, v => {
          return getFileUrl(v);
        });
      }
      return [];
    }
    const posterUrls = resolveFileUrlListByKey(data, 'poster');
    const coverUrls = resolveFileUrlListByKey(data, 'cover');
    const extracoverUrls = resolveFileUrlListByKey(data, 'extracover');
    const extrafanartUrls = resolveFileUrlListByKey(data, 'extrafanart');
    const extrathumbnailUrls = resolveFileUrlListByKey(data, 'extrathumbnail');
    return (
      <div style={{ padding: '8px 8px 24px' }}>
        {
          posterUrls.length > 0 && (
            <>
              <Divider orientation="left">海報</Divider>
              <div className="grid grid-cols-3 gap-1">
                {
                  _.map(posterUrls, (u, i) => <Image key={`poster-${i}`} src={u} />)
                }
              </div>
            </>
          )
        }
        {
          coverUrls.length > 0 && (
            <>
              <Divider orientation="left">封面</Divider>
              <div className="grid grid-cols-3 gap-1">
                {
                  _.map(coverUrls, (u, i) => <Image key={`cover-${i}`} src={u} />)
                }
              </div>
            </>
          )
        }
        {
          extracoverUrls.length > 0 && (
            <>
              <Divider orientation="left">額外封面</Divider>
              <div className="grid grid-cols-3 gap-1">
                {
                  _.map(extracoverUrls, (u, i) => <Image key={`extracover-${i}`} src={u} />)
                }
              </div>
            </>
          )
        }
        {
          extrafanartUrls.length > 0 && (
            <>
              <Divider orientation="left">藝術照</Divider>
              <div className="grid grid-cols-3 gap-1">
                {
                  _.map(extrafanartUrls, (u, i) => <Image key={`extrafanart-${i}`} src={u} />)
                }
              </div>
            </>
          )
        }
        {
          extrathumbnailUrls.length > 0 && (
            <>
              <Divider orientation="left">影片摘要圖</Divider>
              <div className="grid grid-cols-3 gap-1">
                {
                  _.map(extrathumbnailUrls, (u, i) => <Image key={`extrathumbnail-${i}`} src={u} />)
                }
              </div>
            </>
          )
        }
      </div>
    )
  }

  const renderTabChildren = () => {
    switch (activeTab) {
      case AVCardTab.INFO:
        return (
          <div style={{ margin: 8 }}>
            <Space
              direction="vertical"
              style={{ width: '100%' }}
            >
              <div>
                <VideoPlayer
                  cover={videoCoverUrl}
                  src={trailerUrl}
                />
              </div>
              <div>
                <Descriptions
                  size='small'
                  bordered
                  labelStyle={{ minWidth: 120, width: 120 }}
                  column={{ xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 }}
                  items={descriptionItems}
                />
              </div>
            </Space>
          </div>
        );
      case AVCardTab.ATTACHMENT:
        return renderAttachmentTab();
      case AVCardTab.RAW:
        return (
          <div>
            <SyntaxHighlighter language='json' style={vs2015} wrapLines wrapLongLines showLineNumbers>
              {JSON.stringify(data, null, 2)}
            </SyntaxHighlighter>
          </div>
        )
      default:
        return null;
    }
  }

  return (
    <>
      <div
        className="bg-white border border-solid border-gray-300 hover:border-blue-300 rounded hover:shadow-lg cursor-pointer"
        onClick={() => {
          setActiveTab(AVCardTab.INFO);
          setDetailVisible(true);
        }}
      >
        <div className="m-2">
          <div className="w-full relative aspect-[7/10]">
            <Image src={_.isUndefined(titleUrl) ? null : titleUrl} alignCenter={true} />
          </div>
          <div className="py-2 text-sm">
            {
              (data.dn) && <div className="text-center text-blue-500">{data.dn}</div>
            }
            <div className="pt-1 text-xs truncate">{data.title ?? ''}</div>
          </div>
        </div>
      </div>
      <Drawer
        title={data.title}
        placement="right"
        size='large'
        onClose={() => {
          setDetailVisible(false);
        }}
        open={detailVisible}
        destroyOnClose={true}
        styles={{
          body: {
            padding: 0
          }
        }}
      >
        <StickyBox offsetTop={0} style={{ zIndex: 999 }}>
          <Tabs
            centered
            activeKey={activeTab}
            onChange={(k) => setActiveTab(k as AVCardTab)}
            tabBarStyle={{
              marginBottom: 0,
              backgroundColor: 'white'
            }}
            items={[
              {
                key: AVCardTab.INFO,
                label: '資訊'
              },
              {
                key: AVCardTab.ATTACHMENT,
                label: '附件'
              },
              // {
              //   key: AVCardTab.RAW,
              //   label: '原始資料'
              // }
            ]}
          />
        </StickyBox>
        {renderTabChildren()}
      </Drawer>
    </>
  )
}

AVCard.displayName = 'AVCard';

export default AVCard;