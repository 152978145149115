import APIClient from './APIClient';
import DatasetController from './Controller/DatasetController';
import CrawlerController from './Controller/CrawlerController';

class OwObWebAPI extends APIClient {

  get DatasetController(): DatasetController {
    return new DatasetController(this);
  }

  get CrawlerController(): CrawlerController {
    return new CrawlerController(this);
  }
}

export default OwObWebAPI;