// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React, { useMemo } from 'react';

import useAppAuthStateWithType from './context/useAppAuthStateWithType';
import useAppAuthGuardWithType from './context/useAppAuthGuardWithType';
import invokeAppAuthPolicyWithType from './invokeAppAuthPolicyWithType';

import type { IAppAuthPolicy } from './types.d';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IAppAuthGuardProps<S extends object, A extends object, E extends object, G extends object> {
  /**
   * 要執行的驗證權限
   */
  policy: IAppAuthPolicy<S, G> | Array<IAppAuthPolicy<S, G>>
  /**
   * 无权限时的显示，默认无权限不显示任何内容
   */
  fallback?: React.ReactNode;
}

/**
 * 權限驗證組件
 */
const AppAuthGuard = <S extends object, A extends object, E extends object, G extends object>(props: React.PropsWithChildren<IAppAuthGuardProps<S, A, E, G>>) => {
  const { children, policy, fallback } = props;
  const state = useAppAuthStateWithType<S, A, E, G>();
  const guard = useAppAuthGuardWithType<S, A, E, G>();

  const isPass = useMemo(() => {
    return invokeAppAuthPolicyWithType<S, G>(state, guard, policy);
  }, [policy, state, guard]);

  return isPass ? children : fallback;
}

(AppAuthGuard as React.FC<object>).displayName = 'AppAuthGuard';

export default AppAuthGuard;
