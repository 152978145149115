import _ from 'lodash';
import { IRouteProps } from '@/contexts/pageRouteProps';
import { Icon } from '@/contexts/icons';
import { useInfiniteScroll, useRequest, useSize, useUpdate } from 'ahooks';
import { OwObAPI } from '@/services';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Divider, Flex, Input, Select, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import CollectionCard from '@/components/CollectionCard';
import SkeletonCard from '@/components/CollectionCard/SkeletonCard';
import config from '@/config';
import { ICollection } from '@/services/OwOb.WebAPI/Controller/types';

export const routeProps: IRouteProps = {
  pageLayout: 'MainLayout',
  appAuth: {
    policy: 'isLoggedIn'
  },
  menu: {
    label: '網站資料',
    index: 1,
    icon: <Icon icon="mdi:web" fontSize={24} />
  },
  breadcrumb: {
    title: '網站資料'
  }
};

const CollectionPage = () => {
  // Ref
  const pageRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const headerSize = useSize(headerRef);
  // 強制重新渲染
  const update = useUpdate();
  // 當重新縮放視窗大小時，重新渲染
  useEffect(() => {
    const handleResize = () => {
      update();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  // 控制項操作
  const [currentSite, setCurrentSite] = useState<string>('');
  const [currentKeyword, setCurrentKeyword] = useState<string | undefined>();

  // API
  const { data: site, loading: siteLoading } = useRequest(async () => {
    const response = await OwObAPI.DatasetController.GET_CollectionSite();
    return response.data;
  });
  const siteSelectOptions = useMemo(() => {
    return [
      {
        label: '全部',
        value: ''
      },
      ...(_.map(site ?? [], s => {
        return {
          label: s,
          value: s
        }
      }))
    ];
  }, [site]);

  // 搜尋
  const { data, loading, loadingMore, noMore } = useInfiniteScroll<{ list: Array<ICollection>, page: number, total: number, noMore: boolean }>(
    async (currentData) => {
      const current = {
        page: _.get(currentData, 'page', 0),
      }
      const response = await OwObAPI.DatasetController.GET_Collection((_.isEmpty(currentSite) ? undefined : currentSite), {
        keyword: _.isEmpty(currentKeyword) ? undefined : currentKeyword,
        page: current.page + 1,
      });
      const response_data = response.data;
      return {
        list: response_data.data,
        page: response_data.pagination.current,
        total: response_data.pagination.total,
        noMore: _.isEmpty(response_data.data)
      };
    },
    {
      target: pageRef,
      reloadDeps: [currentSite, currentKeyword],
      isNoMore: (currentData) => currentData?.noMore ?? false,
    }
  );

  return (
    <div
      ref={pageRef}
      style={{
        height: `calc(100vh - 48px)`,
        overflow: 'auto'
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 48,
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
          backdropFilter: 'blur(8px)'
        }}
      >
        <div ref={headerRef}>
          <PageHeader
            title="搜尋網站資料"
          >
            <Flex align='center' justify='center'>
              <Space.Compact style={{ width: '100%' }}>
                <Select
                  style={{ width: 200 }}
                  loading={siteLoading}
                  options={siteSelectOptions}
                  value={currentSite}
                  onChange={(v) => {
                    if (pageRef?.current) {
                      pageRef.current.scrollTo(0, 0);
                    }
                    setCurrentSite(v);
                  }}
                />
                <Input
                  placeholder='請輸入關鍵字'
                  value={currentKeyword}
                  onInput={(e) => {
                    if (pageRef?.current) {
                      pageRef.current.scrollTo(0, 0);
                    }
                    setCurrentKeyword((e.target as HTMLInputElement).value);
                  }}
                />
              </Space.Compact>
            </Flex>
          </PageHeader>
        </div>
      </div>
      <div
        style={{ padding: 16, marginTop: (headerSize?.height ?? 0) }}
      >
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-4">
          {
            (loading) && _.map(_.range(20), (i) => {
              return (
                <SkeletonCard key={`collection-loading-skeletoncard-${i}`} active={true} />
              )
            })
          }
          {
            (!loading) && _.map(data?.list ?? [], (d) => {
              return (
                <CollectionCard
                  key={_.get(d, 'id',)}
                  data={d}
                  getFileUrl={f => {
                    return `${config.Service.OwOb.S3URL}/${f.bucketName}/${f.bucketPath}`;
                  }}
                />
              )
            })
          }
          {
            (loadingMore) && (
              <SkeletonCard active={true} />
            )
          }

        </div>
        {
          (noMore) && (
            <Divider plain>沒有更多內容了</Divider>
          )
        }
      </div>
    </div>
  )
}

export default CollectionPage;
