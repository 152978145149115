import _ from 'lodash';
import React from 'react';
import AVCard from './AVCard';
import AnimeCard from './AnimeCard';
import { IAnimeCollectionData, IAVCollectionData, ICollectionDataBase, IFile } from './types';

export interface ICollectionCardProps {
  /**
   * 資料內容
   */
  data: ICollectionDataBase
  /**
   * 取得檔案路徑
   */
  getFileUrl: (file: IFile) => string;
}

const CollectionCard: React.FC<ICollectionCardProps> = (props) => {
  const { data } = props;
  const type = React.useMemo(() => {
    return _.get(data, 'type');
  }, [data]);

  switch (type) {
    case 'av':
      return <AVCard {...props} data={data as IAVCollectionData} />;
    case 'anime':
      return <AnimeCard {...props} data={data as IAnimeCollectionData} />;
    default:
      return null;
  }
}

CollectionCard.displayName = 'CollectionCard';

export default CollectionCard;