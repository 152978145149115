// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import usePageRouteContainer from './usePageRouteContainer';

import folderProps from './pageRoute/folderProps';
import getRouteByKey from './pageRoute/getRouteByKey';
import getPageRouteMapWithType from './pageRoute/getPageRouteMap';
import getPageRouteListWithType from './pageRoute/getPageRouteList';

import useAntdMenuPropsWithType from './menu/useAntdMenuProps';

import useAntdBreadcrumbProps from './breadcrumb/useAntdBreadcrumbProps';

/**
 * 匯出對外方法
 * @returns 
 */
export default function getExport<AppAuthState extends object, AppAuthAction extends object, AppAuthEffect extends object, AppAuthGuard extends object>() {

  const getPageRouteMap = getPageRouteMapWithType<AppAuthState, AppAuthGuard>;
  const getPageRouteList = getPageRouteListWithType<AppAuthState, AppAuthGuard>;
  const useAntdMenuProps = useAntdMenuPropsWithType<AppAuthState, AppAuthAction, AppAuthEffect, AppAuthGuard>;

  return {
    usePageRouteContainer,
    folderProps,
    getRouteByKey,
    getPageRouteMap,
    getPageRouteList,
    useAntdMenuProps,
    useAntdBreadcrumbProps,
  }
}
