// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import _ from '/app/node_modules/lodash';

import React, { useState, useReducer, useMemo, useEffect, useLayoutEffect } from 'react';
import AppAuthContext from './AppAuthContext';


import type { IAppAuth } from '../types.d';

// AppAuth 內部事件
enum PluginAppAuthAction {
  // 設定初始狀態
  SET_INITIAL_STATE = 'PLUGIN_APPAUTH_SET_INITIAL_STATE'
}

const AppAuthContextProvider: React.FC<React.PropsWithChildren<{ appAuthConfig: IAppAuth<object, object, object, object> }>> = ({ appAuthConfig, children }) => {
  const { state: createState, reducer: createReducer, effect: createEffect, guard: createGuard, subscriptions } = appAuthConfig;
  // 將 plugin-appAuth 內部使用的 action 加入至 reducer 中
  const reducer = (prevState: object, action: object) => {
    if (action.type === PluginAppAuthAction.SET_INITIAL_STATE) {
      return action.payload;
    }
    return createReducer(prevState, action);
  };
  // 建立 AppAuth state 用於 AppAuthContext, 初始狀態由後續異步方法取得
  const [state, dispatch] = useReducer(reducer, {});
  const effect = useMemo(() => createEffect({ state, dispatch }), [state, dispatch]);
  const guard = useMemo(() => createGuard({ state }), [state]);

  // 取得初始狀態
  const [initializing, setInitializing] = useState<boolean>(true);
  useLayoutEffect(() => {
    const statePromise = _.isFunction(createState) ? createState() : createState;
    // 無論是 Promise 或是直接物件, 都使用 Promise.resolve 包裹, 以統一處理
    Promise.resolve(statePromise).then((initialState) => {
      dispatch({
        type: PluginAppAuthAction.SET_INITIAL_STATE,
        payload: initialState
      });
      setInitializing(false);
    });
  }, []);

  // 當 AppAuth state 有變化時, 觸發 subscriptions
  useEffect(() => {
    // 如果處於初始化狀態, 不觸發 subscriptions
    if (initializing) return;
    // 如果 subscriptions 不存在, 不觸發 subscriptions
    if (_.isUndefined(subscriptions)) return;
    const offSubscriptions: Array<() => void> = _.reduce(subscriptions, (res, subscription) => {
      const unsubscription = subscription({ state, dispatch });
      if (_.isFunction(unsubscription)) {
        res.push(unsubscription);
      }
      return res;
    }, []);
    return () => {
      _.each(offSubscriptions, (offSubscription: () => void) => {
        if (offSubscription) {
          offSubscription();
        }
      });
    }
  }, [state, subscriptions]);

  if (initializing) {
    // 初始化狀態下不進行後續內容渲染, 若有 loading 組件則使用該組件顯示
    return null;
  }

  return (
    <AppAuthContext.Provider value={{
      state,
      dispatch,
      effect,
      guard
    }}>
      {children}
    </AppAuthContext.Provider>
  )
}

export default AppAuthContextProvider;
