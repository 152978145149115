import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { IAnimeCollectionData, IAVCollectionData, IEPData } from './types';
import { ICollectionCardProps } from '.';
import Image from './components/Image';
import { Col, Descriptions, DescriptionsProps, Divider, Drawer, Flex, Row, Space, Table, TableProps, Tabs, Tag } from 'antd';
import StickyBox from "react-sticky-box";
import VideoPlayer from './components/VideoPlayer';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export interface IAnimeCardProps extends ICollectionCardProps {
  /**
   * 資料內容
   */
  data: IAnimeCollectionData;
}

enum AnimeCardTab {
  INFO = 'INFO',
  EPISODE = 'EPISODE',
  ATTACHMENT = 'FANART',
  RAW = 'RAW'
}

const AnimeCard: React.FC<IAnimeCardProps> = ({ data, getFileUrl }) => {
  const [detailVisible, setDetailVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<AnimeCardTab>(AnimeCardTab.INFO);

  const titleUrl = useMemo<string | undefined>(() => {
    // 嘗試使用 poster
    const poster = _.first(data.poster);
    if (poster && _.isPlainObject(poster)) {
      return getFileUrl(poster);
    }
    return undefined;
  }, [data, getFileUrl]);

  const cardTabItems = useMemo(() => {
    const ep = _.get(data, 'ep', []);
    return [
      {
        key: AnimeCardTab.INFO,
        label: '資訊'
      },
      ...((ep.length > 0) ? [{
        key: AnimeCardTab.EPISODE,
        label: '集數'
      }] : []),
      {
        key: AnimeCardTab.ATTACHMENT,
        label: '附件'
      }
      // {
      //   key: AnimeCardTab.RAW,
      //   label: '原始資料'
      // }
    ];
  }, [data]);

  const descriptionItems = useMemo<DescriptionsProps['items']>(() => {
    const res: DescriptionsProps['items'] = [
      {
        key: 'id',
        label: '資料編號',
        children: data.id
      },
      {
        key: 'key',
        label: '索引',
        children: data.key
      },
      {
        key: 'site',
        label: '來源網站',
        children: data.site
      },
      {
        key: 'siteKey',
        label: '來源網站索引',
        children: data.siteKey
      },
      {
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'category',
        label: '類型',
        children: data.category ?? '-'
      },
      {
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'title',
        label: '標題',
        children: data.title
      }
    ];
    if (data.titleCN) {
      if (!_.isEmpty(data.titleCN)) {
        res.push({
          span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
          key: 'titleCN',
          label: '標題(簡體)',
          children: data.titleCN
        });
      }
    }
    if (_.isArray(data.titleAlias)) {
      res.push({
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'titleAlias',
        label: '標題(別名)',
        children: (
          <>
            {_.map(_.get(data, 'titleAlias', []), (a, i) => (<Tag key={`animecard-${_.get(data, 'id', '')}-titlealias-${i}`}>{a}</Tag>))}
          </>
        )
      })
    }
    if (data.release) {
      const releaseWeekly = data.releaseWeekly;
      res.push({
        span: (!_.isEmpty(releaseWeekly) ? undefined : ({ xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 })),
        key: 'release',
        label: '放送日期',
        children: data.release
      });
      if (!_.isEmpty(releaseWeekly)) {
        res.push({
          key: 'releaseWeekly',
          label: '每周放送時間',
          children: releaseWeekly
        })
      }
    }
    if (data.runtime) {
      res.push({
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'runtime',
        label: '片長',
        children: data.runtime
      });
    }
    const outline = _.get(data, 'outline');
    if (!_.isEmpty(outline)) {
      res.push({
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'outline',
        label: '描述',
        children: outline
      })
    }
    if (data.website) {
      const website = _.get(data, 'website');
      res.push({
        span: { xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 },
        key: 'website',
        label: '官網',
        children: (() => {
          if (_.isString(website)) {
            return <a href={website} target='_blank'>{website}</a>
          }
          if (_.isArray(website)) {
            return (
              <Space direction='vertical' size='small'>
                {_.map(website, (w, i) => (
                  <a key={`animecard-${_.get(data, 'id', '')}-website-${i}`} href={w} target='_blank'>{w}</a>
                ))}
              </Space>
            )
          }
          return null;
        })()
      })
    }
    return res;
  }, [data]);


  const episodeColumns: TableProps<IEPData>['columns'] = [
    {
      title: '#',
      key: 'ep',
      width: 60,
      align: 'right',
      render: (__, row) => {
        return `${row.type} ${row.num}`
      }
    },
    {
      key: 'title',
      dataIndex: 'title',
      title: '標題',
    },
    {
      key: 'titleCN',
      dataIndex: 'titleCN',
      title: '標題(簡體)',
    },
    {
      key: 'release',
      dataIndex: 'release',
      title: '放送日',
      width: 100,
      align: 'center',
    },
    {
      key: 'runtime',
      dataIndex: 'runtime',
      title: '時長',
      width: 100,
      align: 'center',
    }
  ];

  const renderAttachmentTab = () => {
    const resolveFileUrlListByKey = (d: IAnimeCollectionData, dk: string): Array<string> => {
      const dataValue = _.get(d, dk, []);
      if (_.isArray(dataValue)) {
        return _.map(dataValue, v => {
          return getFileUrl(v);
        });
      }
      return [];
    }
    const posterUrls = resolveFileUrlListByKey(data, 'poster');
    return (
      <div style={{ padding: '8px 8px 24px' }}>
        {
          posterUrls.length > 0 && (
            <>
              <Divider orientation="left">海報</Divider>
              <div className="grid grid-cols-3 gap-1">
                {
                  _.map(posterUrls, (u, i) => <Image key={`animecard-poster-${i}`} src={u} />)
                }
              </div>
            </>
          )
        }
      </div>
    )
  }

  const renderTabChildren = () => {
    switch (activeTab) {
      case AnimeCardTab.INFO:
        return (
          <div style={{ margin: 8 }}>
            <Space
              direction="vertical"
              style={{ width: '100%' }}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: 300 }}>
                  <Image src={titleUrl ?? null} />
                </div>
              </div>
              <div>
                <Descriptions
                  size='small'
                  bordered
                  labelStyle={{ minWidth: 120, width: 120 }}
                  column={{ xs: 1, sm: 2, md: 2, xl: 2, xxl: 2 }}
                  items={descriptionItems}
                />
              </div>
            </Space>
          </div>
        );
      case AnimeCardTab.EPISODE:
        return (
          <div className="md:m-2">
            <Table<IEPData>
              dataSource={_.get(data, 'ep', [])}
              size="small"
              pagination={false}
              rowKey={(r) => `animecard-ep-${r.type ?? 'null'}-${r.num ?? 'null'}`}
              columns={episodeColumns}
              tableLayout='auto'
              sticky={{
                offsetHeader: 46 // Tab 高度
              }}
            />
          </div>
        )
      case AnimeCardTab.ATTACHMENT:
        return renderAttachmentTab();
      case AnimeCardTab.RAW:
        return (
          <div>
            <SyntaxHighlighter language='json' style={vs2015} wrapLines wrapLongLines showLineNumbers>
              {JSON.stringify(data, null, 2)}
            </SyntaxHighlighter>
          </div>
        )
      default:
        return null;
    }
  }

  return (
    <>
      <div
        className="bg-white border border-solid border-gray-300 hover:border-blue-300 rounded hover:shadow-lg cursor-pointer"
        onClick={() => {
          setActiveTab(AnimeCardTab.INFO);
          setDetailVisible(true);
        }}
      >
        <div className="m-2">
          <div className="w-full relative aspect-[7/10]">
            <Image src={_.isUndefined(titleUrl) ? null : titleUrl} alignCenter={true} />
          </div>
          <div className="py-2 text-sm">
            {
              (data.key) && <div className="text-center text-blue-500">{data.key}</div>
            }
            <div className="pt-1 text-xs truncate">{data.title ?? ''}</div>
          </div>
        </div>
      </div>
      <Drawer
        title={data.title}
        placement="right"
        size='large'
        onClose={() => {
          setDetailVisible(false);
        }}
        open={detailVisible}
        destroyOnClose={true}
        styles={{
          body: {
            padding: 0
          }
        }}
      >
        <StickyBox offsetTop={0} style={{ zIndex: 999 }}>
          <Tabs
            centered
            activeKey={activeTab}
            onChange={(k) => setActiveTab(k as AnimeCardTab)}
            tabBarStyle={{
              marginBottom: 0,
              backgroundColor: 'white'
            }}
            items={cardTabItems}
          />
        </StickyBox>
        {renderTabChildren()}
      </Drawer>
    </>
  )
}

AnimeCard.displayName = 'AnimeCard';

export default AnimeCard;